var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"card-area",attrs:{"bordered":false}},[_c('div',{staticClass:"data-area",attrs:{"hidden":""}},[_c('statistics-data-item',{attrs:{"title":"设备总数","value":_vm.statistics.total,"bgColor":"#F6F9FC","color":"#0084ff","icon":_vm.$image('ic_device_fault_total@3x.png')}}),_c('statistics-data-item',{attrs:{"title":"在线设备","value":_vm.statistics.totalOnline,"bgColor":"#F3FFF8","color":"#0BD169","icon":_vm.$image('ic_device_fault_processed@3x.png')}}),_c('statistics-data-item',{attrs:{"title":"今日新增设备","value":_vm.statistics.totalToday,"bgColor":"#FFFAF5","color":"#FFB764","icon":_vm.$image('ic_device_fault_today@3x.png')}}),_c('statistics-data-item',{attrs:{"title":"近7天新增设备","value":_vm.statistics.totalWeek,"bgColor":"#FFF3F3","color":"#FF7B7B","icon":_vm.$image('ic_device_fault_7@3x.png')}})],1),_c('common-table',{ref:"table",attrs:{"path":"device","search-placeholder":"搜索设备名称/MAC/固件版本","columns":_vm.columns,"queryParams":_vm.queryParams},on:{"reset":_vm.reset},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var record = ref.record;
return [_c('img',{staticClass:"product-icon",attrs:{"src":record.devicePicture}})]}},{key:"firmware",fn:function(ref){
var record = ref.record;
return [_c('a-tag',{attrs:{"color":"blue"}},[_vm._v(_vm._s(record.firmwareVersion))])]}},{key:"mcuVersion",fn:function(ref){
var record = ref.record;
return [_c('a-tag',{attrs:{"color":"blue"}},[_vm._v(_vm._s(record.mcuVersion))])]}},{key:"status",fn:function(ref){
var record = ref.record;
return [(record.status==1)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("在线")]):_c('a-tag',{attrs:{"color":"cyan"}},[_vm._v("离线")])]}},{key:"operation",fn:function(ref){
var record = ref.record;
return [_c('action-upgrade',{on:{"click":function($event){return _vm.upgrade(record)}}})]}}])},[_c('template',{slot:"search"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('a-select',{staticClass:"table-select",staticStyle:{"width":"10rem"},on:{"change":_vm.productChange},model:{value:(_vm.queryParams.productKey),callback:function ($$v) {_vm.$set(_vm.queryParams, "productKey", $$v)},expression:"queryParams.productKey"}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("全部产品")]),_vm._l((_vm.productList),function(product){return _c('a-select-option',{attrs:{"value":product.productKey}},[_vm._v(_vm._s(product.name)+" ")])})],2)],1),_c('div',[_vm._v(" 地址： "),_c('a-input',{staticStyle:{"width":"15rem"},model:{value:(_vm.queryParams.address),callback:function ($$v) {_vm.$set(_vm.queryParams, "address", $$v)},expression:"queryParams.address"}})],1)])])],2),_c('firmware-select',{ref:"firmwareSelect"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }